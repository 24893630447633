<template>
  <div>
    <notifications group="notification" position="bottom right" />
    <div v-if="discountsBundles.length > 0" class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b card-stretch">
          <div class="card-header border-0 pt-5">
            <h3 class="card-title">
              <span class="card-label font-weight-bolder text-dark"
                >All Bundles</span
              >
            </h3>
            <div class="card-toolbar">
              <router-link
                to="/discounts-bundles/new"
                class="btn btn-mc font-weight-bolder"
              >
                <span class="svg-icon svg-icon-md svg-icon-white">
                  <inline-svg
                    src="media/svg/icons/Communication/Add-user.svg"
                  />
                </span>
                Add New Bundle
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="table table-head-custom table-head-bg table-vertical-center"
              >
                <thead>
                  <tr class="bg-gray-100 text-left">
                    <th style="min-width: 250px" class="pl-7">Name</th>
                    <th style="min-width: 110px" class="pr-7 text-right">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(bundle, key) in discountsBundles" :key="key">
                    <td class="py-4" :class="{ 'border-top-0': key === 0 }">
                      <router-link
                        :to="'/discounts-bundles/edit/' + bundle.id"
                        class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >{{ bundle.name }}</router-link
                      >
                    </td>
                    <td
                      class="pr-0 text-right"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      <router-link
                        :to="'/discounts-bundles/edit/' + bundle.id"
                        class="btn btn-icon btn-light btn-hover-light-primary btn-sm mr-3"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg
                            src="media/svg/icons/Communication/Write.svg"
                          />
                        </span>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-md-12 text-center">
        <b-spinner variant="primary" type="grow"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '../../../core/services/api.service'

export default {
  name: 'DiscountsBundles',
  data() {
    return {
      discountsBundles: []
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'All Discounts Bundles', route: '/companies/list' }
    ])

    ApiService.get('discounts_bundles').then(({ data }) => {
      this.discountsBundles = [...data['hydra:member']]
      this.isLoaded = true
    })
  },
  methods: {}
}
</script>
